import { tns } from 'tiny-slider/src/tiny-slider';
import bindArrows from './common';

document.querySelectorAll('[data-slide-column-slider]')
  .forEach((item) => {
    const countSlider = tns({
      container: item.querySelector('[data-slide-column-count-slider]'),
      slideBy: 1,
      items: 1,
      nav: false,
      speed: 700,
      controls: false,
      loop: false,
      rewind: false,
      mode: 'gallery',
    });

    const imageSlider = tns({
      container: item.querySelector('[data-slide-column-image-slider]'),
      slideBy: 1,
      items: 1,
      nav: false,
      speed: 700,
      controls: false,
      loop: false,
      rewind: false,
      mode: 'gallery',
    });

    const descriptionSlider = tns({
      container: item.parentNode.querySelector('[data-slide-column-description-slider]'),
      slideBy: 1,
      items: 1,
      nav: false,
      speed: 700,
      controls: false,
      loop: false,
      rewind: false,
      touch: false,
      mode: 'gallery',
    });

    bindArrows(
      '[data-slide-column-slider-left]',
      '[data-slide-column-slider-right]',
      imageSlider,
      item,
    );

    const syncSliders = function (info) {
      if (info.container.hasAttribute('data-slide-column-image-slider')) {
        countSlider.goTo(info.index);
        descriptionSlider.goTo(info.index);
      }
    };

    imageSlider.events.on('transitionStart', syncSliders);
  });