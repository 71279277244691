const easeOutCubic = (x) => {
    return 1 - Math.pow(1 - x, 3);
}

const duration = 1500;

export const scrollToTarget = (target, offset, origin) => {
    origin = origin || window;
    offset = offset || 0;

    let top = target.getBoundingClientRect().top;
    if (window.innerWidth > 1023) {
        top = target.getBoundingClientRect().left;
    }
    const startPos = origin.pageYOffset;
    const diff = top + offset;

    let startTime = null;
    let requestId;

    const loop = (currentTime) => {
        if (!startTime) {
            startTime = currentTime;
        }

        const time = currentTime - startTime;

        const percent = Math.min(time / duration, 1);
        origin.scrollTo(0, startPos + diff * easeOutCubic(percent));

        if (time < duration) {
            // Continue moving
            requestId = origin.requestAnimationFrame(loop);
        } else {
            origin.cancelAnimationFrame(requestId);
        }
    };
    requestId = origin.requestAnimationFrame(loop);
}