class RailObject {
  constructor(container) {
    this.railContainer = container;
    this.rail = this.railContainer.querySelector('[data-rail]');
    this.rails = Array.from(this.rail.querySelectorAll('[data-rail-item]'));
    this.offset = 0;
    this.selfOfset = 0;
    this.lastScrollTop = 0;
    this.lastScrollDirection = 'down';

    this.update();
  }

  computedOffset(scrollTop) {
    // console.log(scrollTop);
    // console.log(this.lastScrollTop);

    if (scrollTop < this.lastScrollTop) {
      this.selfOfset--;
      this.lastScrollDirection = 'up';
    } else if (scrollTop > this.lastScrollTop) {
      this.selfOfset++;
      this.lastScrollDirection = 'down';
    } else if (this.lastScrollDirection === 'up') {
      this.selfOfset--;
    } else if (this.lastScrollDirection === 'down') {
      this.selfOfset++;
    }
    this.lastScrollTop = scrollTop;

    this.offset = (scrollTop + this.selfOfset) * -1;
    if (Math.abs(this.offset) >= this.rails[1].offsetWidth) {
      this.offset = this.offset % this.rails[1].offsetWidth;
    }
    this.setStyles();
  }

  setStyles() {
    this.rail.style.transform = `translate3d(${this.offset}px, 0, 0)`;
  }

  update(scrollOffset) {
    this.computedOffset(scrollOffset);
  }
}

export default RailObject;