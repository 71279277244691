class ButtonObject {
  constructor(container) {
    this.button = container;
    this.eventListeners();
  }

  eventListeners() {
    this.button.addEventListener('click', () => {
      document.dispatchEvent(new CustomEvent('ButtonClicked', {
        detail: { toWhichSlide: parseInt(this.button.dataset.scrollButton) - 1 },
      }));
    });
  }
}

export default ButtonObject;