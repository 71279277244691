import LazyLoader from './lazy';

class Preloader {
  constructor(element) {
    // чтобы получить количество картинок нужных для загрузки
    this.lazyLoader = new LazyLoader();
    // Кол-во всех картинок для загрузки
    this.lazyStartLength = this.lazyLoader.elements.length;
    // Сколько осталось загрузить
    this.lazyCurrentLength = this.lazyStartLength;

    // Элемент прелоадера
    this.element = element;
    this.elementBackground = this.element.querySelector('[data-preloader-background]');
    // Значение в прелоадере
    this.valueElement = this.element.querySelector('[data-preloader-value]');
    // приводим к числу
    this.value = parseInt(this.valueElement.innerHTML, 10);
    // Все даты
    this.yearsElems = Array.from(document.querySelectorAll('[data-preloader-year]'));
    // Запоминаем дату с которой начинается отсчет
    this.startValue = this.value;
    // Получаем дату к которой придет счетчик
    this.startDate = 1976;
    // this.startDate = this.getStartDate();
    // Разница между начальным и конечным годом для расчета
    this.gap = this.value - this.startDate;

    this.ease = 0.1;

    this.wasLoaded = 0;
    this.shouldBeLoaded = 0;
    this.interval = 30;
    setInterval(() => this.setShouldBeLoaded(), this.interval);
    this.eventListeners();
    this.update();
  }

  setShouldBeLoaded() {
    if (this.shouldBeLoaded < this.lazyStartLength) {
      this.shouldBeLoaded++;
    }
  }

  getStartDate() {
    this.years = [];
    this.yearsElems.forEach((item) => {
      this.years.unshift(item.dataset.preloaderYear);
    });
    return parseInt(Math.min.apply(Math, this.years), 10);
  }

  setValueByLoader() {
    const length = this.value - this.startDate;
    const loadPercent = (this.lazyStartLength - this.lazyCurrentLength) / 100;
    this.value = Math.floor(this.value - (length * loadPercent) * this.ease);

    if (this.lazyCurrentLength === 0) {
      setTimeout(() => {
        this.elementBackground.style.height = '100vh';
        this.setScrollToZero();
        setTimeout(() => {
          this.element.style.height = '0';
          document.dispatchEvent(new CustomEvent('PreloaderLoaded', {}));
          this.lazyLoader.setAutoPlayToVideos();
        }, 1600);
      }, 800);
    }
  }

  setScrollToZero() {
    if (document.documentElement.scrollTop !== 0) {
      window.scrollTo(0, 0);
    }
  }

  eventListeners() {
    document.addEventListener('ImageLoaded', () => {
      this.wasLoaded++;

      if (this.wasLoaded === this.shouldBeLoaded) {
        this.setCurrentLengthMinus();
      } else {
        setTimeout(() => {
          this.setCurrentLengthMinus();
        }, (this.wasLoaded - this.shouldBeLoaded) * this.interval);
      }
    });
  }

  setCurrentLengthMinus() {
    this.lazyCurrentLength--;
    this.setValueByLoader();
  }

  setStyles() {
    if (this.lazyCurrentLength === 0 && this.value > this.startDate) {
      this.value--;
    }
    this.valueElement.innerHTML = this.value;
  }

  update() {
    this.setStyles();
    window.requestAnimationFrame(this.update.bind(this));
  }
}

function init() {
  setTimeout(() => {
    setTimeout(() => {
      const preloader = new Preloader(document.querySelector('[data-preloader]'));
    }, 1400);
  }, 2000);
}


document.addEventListener('DOMContentLoaded', init);