class ImageObject {
  constructor(container) {
    this.container = container; // Родитель элемента с изображением или видео
    const image = this.container.querySelector('img');
    const video = this.container.querySelector('[data-parallax-container-inner-video]');
    this.element = image || video;
    this.elementContainer = this.container.querySelector('[data-parallax-scale]');
    this.startScale = parseFloat(this.container.dataset.startScale) || 1.9; // Изначальное (максимальное) увеличение
    this.startOffset = parseFloat(this.container.dataset.startOffset) || -16; // Изначальное (максимальное) смещение в px

    this.ratioScale = this.startScale;
    this.offsetX = this.startOffset;

    this.startPosition = this.container.getBoundingClientRect().left;
    this.startWidth = this.container.getBoundingClientRect().width;

    this.update();
  }

  removeTransition() {
   setTimeout(() => {
     this.element.style.transitionDuration = '0s';
     this.element.style.webkitTransitionDuration = '0s';
   }, 1400);
  }

  setStyles() {
    if (this.elementContainer) {
      this.elementContainer.style.transform = `scale(${this.scale})`;
      this.elementContainer.style.webkitTransform = `scale(${this.scale})`;
    }
    this.element.style.transform = `translate3d(${this.offsetX}%, 0, 0)`;
    this.element.style.webkitTransform = `translate3d(${this.offsetX}%, 0, 0)`;
  }

  computedRatio() {
    const bounding = this.container.getBoundingClientRect();
    const left = bounding.left;
    const width = bounding.width;
    const right = left + width;
    const middle = left + width / 2;
    const windowWidth = window.innerWidth;
    const halfWindow = window.innerWidth / 2;

    if (left < windowWidth) {
      this.ratioScale = 1.4;
      this.removeTransition();
    }

    if (left > windowWidth) {
      this.ratioOffset = 1;
    } else if (right < 0) {
      this.ratioOffset = -1;
    } else if (middle === halfWindow) {
      this.ratioOffset = 0;
    } else {
      this.ratioOffset = (middle - halfWindow) / (halfWindow + width / 2);
      // this.ratioOffset = left / (window.innerWidth + width);
    }
  }

  computedStyles() {
    this.scale = this.ratioScale;
    this.offsetX = this.ratioOffset * this.startOffset;
  }

  update() {
    this.computedRatio();
    this.computedStyles();
    this.setStyles();
  }
}

export default ImageObject;