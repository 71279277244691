class PictureObject {
  constructor(container) {
    this.element = container; // Родитель элемента с изображением или видео
    this.startOffset = parseFloat(this.element.dataset.startOffset) || -60; // Изначальное (максимальное) смещение в %

    this.offsetX = this.startOffset; // текущее смещение изображения

    this.update();
  }

  setStyles() {
    this.element.style.transform = `translate3d(${this.offsetX}px, 0, 0)`;
  }

  computedRatio() {
    const bounding = this.element.getBoundingClientRect();
    const left = bounding.left;
    const width = bounding.width;
    const right = left + width;
    const middle = left + width / 2;
    const halfWindow = window.innerWidth / 2;

    if (left > window.innerWidth) {
      this.ratioOffset = 1;
    } else if (right < 0) {
      this.ratioOffset = -1;
    } else if (middle > halfWindow) {
      this.ratioOffset = (middle - halfWindow) / (halfWindow + width / 2);
    } else if (middle < halfWindow) {
      this.ratioOffset = (middle - halfWindow) / (halfWindow + width / 2);
    } else {
      this.ratioOffset = 0;
    }
  }

  computedStyles() {
    this.offsetX = this.ratioOffset * this.startOffset;
  }

  update() {
    this.computedRatio();
    this.computedStyles();
    this.setStyles();
  }
}

export default PictureObject;