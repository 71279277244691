const bindArrows = (left, right, slider, tabItem) => {
    let leftArrows = null;
    let rightArrows = null;
    if (!tabItem) {
        leftArrows = document.querySelectorAll(left);
        rightArrows = document.querySelectorAll(right);
    } else {
        leftArrows = tabItem.querySelectorAll(left);
        rightArrows = tabItem.querySelectorAll(right);
    }

    leftArrows.forEach((leftArrow) => {
        leftArrow.addEventListener('click', () => {
            slider.pause();
            slider.goTo('prev');
            slider.play();
        });
    });

    rightArrows.forEach((rightArrow) => {
        rightArrow.addEventListener('click', () => {
            slider.pause();
            slider.goTo('next');
            slider.play();
        });
    });
};

export default bindArrows;