
/**
 * Что тут происходит?
 * 1. Ожидается событие DOMContentLoaded
 * 2. Создается экземпляр класса LazyLoader
 * 3. При инициализации собираются все элементы [data-lazy-load] без [data-initialized="true"]
 * 4. Из собранных элементов выбираются элементы с [data-important="true"]
 *  а) Есть один или более элемент с [data-important="true"]
 *    - Сохраняется количество картинок с [data-important="true"] в this.importantCount
 *    - Для этих элементов подставляется настоящая картинка
 *    - Указывается [data-initialized="true"]
 *    - При завершении отправляется событие ImageLoaded на document
 *    - При завершении загрузки картинки this.importantCount уменьшается на 1
 *    - Если это была последняя картинка, то
 *      - если уже сработало событие load у window, то вызывается this.bindOther() - биндинг остальных картинок
 *      - если событие load у window еще не сработало, то оно ожидается, а когда оно сработает вызовет this.bindOther()
 *   б) Если нет элементов с [data-important="true"]
 *    - если уже сработало событие load у window, то вызывается this.bindOther() - биндинг остальных картинок
 *    - если событие load у window еще не сработало, то оно ожидается, а когда оно сработает вызовет this.bindOther()
 * 5. this.bindOther()
 *  - Подставляются настоящие картинки
 *  - Указывается [data-initialized="true"]
 *  - При завершении отправляется событие ImageLoaded на document
 *
 * Что еще?
 * 1. Класс ожидает событие DOMContentMutated
 * 2. Выполняется повторная переинициализация
 *  - Поскольку старые элементы с [data-important="true"] биндятся сразу, на них уже висит [data-initialized="true"]
 *  - TODO DOMContentMutated может сработать до загрузки всех старых картинок с [data-important="true"], что тогда?
 *  - Старые элементы без [data-important="true"] пойдут в очередь за новыми элементами с [data-important="true"]
 *
 *  Зачем [data-important="true"]?
 *  Скрипты, ожидающие загрузку изображений первого экрана должны отработать раньше,
 *  а не ждать пока загрузятся все картинки на странице. Картинки большие и их может быть много
 *
 *  TODO [data-important="true"] можно засунуть в DOMContentLoaded
 */
class LazyLoader {
  constructor() {
    this.importantCount = 0;
    this.windowLoaded = document.readyState === 'complete';
    this.lazyVideos = Array.from(document.querySelectorAll('[data-autoplay-video]'));

    this.init();
    this.eventListeners();
  }

  init() {
    this.collect();
    this.bind();
  }

  setAutoPlayToVideos() {
    this.lazyVideos.forEach((item) => {
      item.setAttribute('autoplay', '');
      item.controls = false;
      item.playsinline = true;
      item.muted = true;
      item.setAttribute('muted', '');
      item.autoplay = true;
    });

    let autoplayInitialized = false;

    function autoplayNow(item) {
      const promise = item.play();
      if (promise.then) {
        promise
          .then(() => {})
          .catch(() => {
          });
      }
    }

    window.addEventListener('scroll', () => {
      if (autoplayInitialized) {
        return;
      }
      autoplayInitialized = true;
      let timeout = 0;
      this.lazyVideos.forEach((item) => {
        setTimeout(() => {
          autoplayNow(item);
        }, timeout);
        timeout += 500;
      });
    });
  }

  collect() {
    this.elements = Array.from(document.querySelectorAll('[data-lazy-load]:not([data-initialized="true"])'));
  }

  bind() {
    const importantList = this.elements.filter(element => element.dataset.important === 'true');
    this.importantCount = importantList.length;
    if (this.importantCount) {
      importantList.forEach(element => this.bindElement(element));
    } else if (this.windowLoaded) {
      this.bindOther();
    }
  }

  bindOther() {
    this.elements.filter(element => element.dataset.important !== 'true').forEach((element) => {
      this.bindElement(element);
    });
  }

  bindElement(element) {
    if (element.tagName === 'PICTURE') {
      this.setImg(element);
      this.setSources(element);
    } else if (element.tagName === 'VIDEO') {
      this.setVideo(element);
    }

    element.dataset.initialized = 'true';
  }

  setVideo(element) {
    element.querySelectorAll('source').forEach((source) => {
      source.src = source.dataset.src;
    });
  }

  setSources(element) {
    element.querySelectorAll('source').forEach((source) => {
      source.srcset = source.dataset.srcset;
    });
  }

  setImg(element) {
    const img = element.querySelector('img');
    img.src = img.dataset.src;
    img.srcset = img.dataset.src;
    img.onload = () => this.onloadEvent(element);
  }

  onloadEvent(element) {
    document.dispatchEvent(new CustomEvent('ImageLoaded', {
      detail: { pictureElement: element },
    }));

    if (element.dataset.important === 'true') {
      this.importantCount--;
      if (!this.importantCount && this.windowLoaded) {
        this.bindOther();
      }
    }
  }

  windowOnLoad() {
    window.loaded = true;
    if (!this.importantCount) {
      this.bindOther();
    }
  }

  eventListeners() {
    const instance = this;
    document.addEventListener('DOMContentMutated', () => {
      instance.init();
    });

    window.addEventListener('load', () => instance.windowOnLoad());
  }
}

// document.addEventListener('DOMContentLoaded', (e) => {
//   const lazyLoader = new LazyLoader();
// });

export default LazyLoader;