class StickyYearTitle {
  constructor(selector) {
    this.element = selector;
    this.element.left = this.element.getBoundingClientRect().left; // начальная позиция элемента
    this.padding = 120; // отступ от края экрана
    this.offsetX = 0;
    this.slide = {
      element: false,
      right: 0,
    };
    this.getSlide();
  }

  setStyles() {
    this.element.style.transform = `translate3d(${this.offsetX * -1}px, 0, 0)`;
  }

  // Если транслейт больше левой части сдвигаемого (элемента + паддинг)
  // и транслейт меньше правой стороны слайда + ширина элемента + паддинг
  // в котором находится сдвигаемый элемент
  // тогда высчитывается смещение и элемент катается туда сюда
  computedOffset(offset) {
    if (offset < (this.element.left * -1) + this.padding &&
        offset > (this.slide.right * -1) + (this.element.offsetWidth + this.padding * 2)) {
      this.offsetX = this.element.left - this.padding + offset;
      this.setStyles();
    }
  }

  getSlide() {
    this.slide.element = this.element.parentNode.parentNode;
    this.slide.right = this.slide.element.getBoundingClientRect().right;
  }

  update(offset) {
    this.computedOffset(offset);
  }
}

export default StickyYearTitle;