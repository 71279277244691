class StickyLogo {
  constructor(selector, slides) {
    this.logo = document.querySelector(selector);
    this.logo.svg = this.logo.querySelector('[data-logo-svg]');
    this.logo.parts = this.logo.svg.querySelectorAll('path');
    this.offsetX = 0;
    this.slides = [];

    slides.forEach((item) => {
      const slideObject = {
        startPosition: item.getBoundingClientRect().left,
        endPosition: item.getBoundingClientRect().right,
        color: item.dataset.itemColor,
      };
      this.slides.push(slideObject);
    });

  }

  setColor(color) {
    this.logo.parts.forEach((item) => {
      item.setAttribute('fill', `${color}`);
    });
  }

  setScrolled() {
    this.logo.style.transform = `translate3d(${this.offsetX * -1}px, 0, 0)`;
  }

  computedStyles(offset) {
    if (offset < window.innerWidth * -1) {
      this.offsetX = window.innerWidth + offset;
      this.setScrolled();

      const itemSlide = this.slides.find((item) => {
        const right = Math.abs(offset) + this.logo.offsetWidth;
        return right >= item.startPosition && right <= item.endPosition;
      });
      if (itemSlide) {
        if (itemSlide.color === '_white') {
          this.setColor('var(--blue-color)');
        } else if (itemSlide.color === '_black') {
          this.setColor('var(--red-color-dark)');
        } else if (itemSlide.color === '_red') {
          this.setColor('#fff');
        }
      }
    }
  }

  update(offset) {
    this.computedStyles(offset);
  }
}

export default StickyLogo;