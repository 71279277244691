class AwaitAnimationRemover {
  constructor() {
    this.awaitAnimItems = Array.from(document.querySelectorAll('._await-anim'));
    this.overflowContainers = Array.from(document.querySelectorAll('[data-overflow-parrent]'));

    this.update();
  }

  removeListeners() {
    this.awaitAnimItems.forEach((item) => {
      const bounds = item.getBoundingClientRect();
      if (window.innerWidth > 1023) {
        if (bounds.left < window.innerWidth) {
          if (item.classList.contains('_await-anim') &&
              item.hasAttribute('data-await-anim')) {
            document.addEventListener('PreloaderLoaded', () => {
              setTimeout(() => item.classList.remove('_await-anim'), 600);
            });
          } else {
            item.classList.remove('_await-anim');
          }
        }
      } else if (bounds.top < window.innerHeight) {
        if (item.classList.contains('_await-anim') &&
          item.hasAttribute('data-await-anim')) {
          document.addEventListener('PreloaderLoaded', () => {
            setTimeout(() => item.classList.remove('_await-anim'), 600);
          });
        } else {
          item.classList.remove('_await-anim');
          if (item.getAttribute('data-overflow-child') !== null &&
            item.getAttribute('data-overflow-child') !== '') {
            const id = item.getAttribute('data-overflow-child');
            this.overflowContainersAnimate(item, parseInt(id, 10));
          }
        }
        // if (item.classList.contains('_await-anim')) {
        //   item.classList.remove('_await-anim');
        // }
      }
    });
  }

  // наверное нужно как то по другому, когда придумается переделать
  overflowContainersAnimate(item, id) {
    this.overflowContainers.forEach((container) => {
      const containerId = parseInt(container.getAttribute('data-overflow-parrent'), 10);
      if (id === containerId) {
        if (item.parentNode.parentNode.querySelector('[data-overflow-text]')) {
          item.parentNode.parentNode.querySelector('[data-overflow-text]').classList.remove('_await-anim');
        } else if (item.parentNode.parentNode.parentNode.querySelector('[data-overflow-text]')) {
          item.parentNode.parentNode.parentNode.querySelector('[data-overflow-text]').classList.remove('_await-anim');
        }
      }
    });
  }

  update() {
    this.removeListeners();
  }
}

export default AwaitAnimationRemover;