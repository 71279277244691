import RailObject from './objects/RailObject';
import ImageObject from './objects/ImageObject';
import PictureObject from './objects/PictureObject';
import StickyLogo from './sticky/stickyLogo';
import StickyYearTitle from './sticky/stickyYearTitle';
import ButtonObject from './objects/ButtonObject';
import AwaitAnimationRemover from '../animation/awaitAnimationRemover';
import { scrollToTarget } from '../../components/scroll/scroll';

class HorizonScroller {
  constructor(element) {
    this.element = element;
    this.inner = this.element.querySelector('.slides__inner');
    this.list = this.element.querySelector('[data-horizon-list]');
    this.items = Array.from(this.list.querySelectorAll('[data-horizon-item]'));
    this.awaitAnimationRemover = new AwaitAnimationRemover();
    this.status = 'inactive';
    this.sliderWidth = this.items.length * window.innerWidth;
    this.scrolled = 0;
    this.ease = 0.1;
    this.translate = 0;
    this.rightBorder = 0;

    this.positionObject = this.getPositionObject();

    // Контейнер прокрутки
    this.progressBarContainer = this.element.querySelector('[data-horizon-container]');
    // Полоски прокрутки
    this.progressLines = Array.from(this.progressBarContainer.querySelectorAll('[data-horizon-progress]'));
    // Даты прокрутки
    this.progressDates = Array.from(this.progressBarContainer.querySelectorAll('[data-horizon-value]'));
    // Объекты слайдов с левой позицией и шириной слайда
    this.valuePositions = {};
    // Текущее десятилетие
    this.currentDecade = {
      element: null,
      percent: 0,
    };
    this.availBorder = 1.25 * window.innerWidth;

    this.preloaderLoaded = false;

    this.init();
    this.eventListeners();
    this.update();
  }

  init() {
    this.elementModify();
    this.bind();
    this.collect();
    this.initSticky();
  }

  eventListeners() {
    document.addEventListener('PreloaderLoaded', () => {
      this.preloaderLoaded = true;
    });
    document.addEventListener('ButtonClicked', (e) => {
      const target = this.items[e.detail.toWhichSlide];
      scrollToTarget(target);
    });
  }

  update() {
    this.setTranslate();
    this.awaitAnimationRemover.update();

    this.imageObjects
      .filter((imageObject) => {
        if (imageObject.startPosition >= this.translate) {
          return imageObject.startPosition - this.translate < this.availBorder;
        }
        return this.translate - imageObject.startPosition < imageObject.element.offsetWidth;
      })
      .forEach((imageObject) => {
        imageObject.update();
      });

    this.pictureObjects.forEach((pictureObject) => {
      pictureObject.update();
    });

    this.railObjects.forEach((railObject) => {
      railObject.update((this.translate / 3));
    });

    this.logo.update(this.translate * -1, this.items);

    this.stickyYearTitleObjects.forEach((stickyYearTitle) => {
      stickyYearTitle.update(this.translate * -1);
    });

    this.progressHandle();

    window.requestAnimationFrame(this.update.bind(this));
  }

  initStickyLogo() {
    this.logo = new StickyLogo('[data-logo]', this.items);
  }

  initStickyYearTitle() {
    this.stickyYearTitleObjects = [];
    Array.from(document.querySelectorAll('[data-sticky-year-title]'))
      .forEach((container) => {
        this.stickyYearTitleObjects.push(new StickyYearTitle(container));
      });
  }

  initSticky() {
    this.initStickyLogo();
    this.initStickyYearTitle();
  }

  collect() {
    this.collectImages();
    this.collectPictures();
    this.collectRails();
    this.collectButtons();
  }

  collectImages() {
    this.imageObjects = [];
    Array.from(document.querySelectorAll('[data-parallax-container-inner]'))
      .forEach((container) => {
        this.imageObjects.push(new ImageObject(container));
      });
  }

  collectPictures() {
    this.pictureObjects = [];
    Array.from(document.querySelectorAll('[data-parallax-container]'))
      .forEach((container) => {
        this.pictureObjects.push(new PictureObject(container));
      });
  }

  collectRails() {
    this.railObjects = [];
    Array.from(document.querySelectorAll('[data-rail-container]')).forEach((container) => {
      this.railObjects.push(new RailObject(container));
    });
  }

  collectButtons() {
    this.buttonObjects = [];
    Array.from(document.querySelectorAll('[data-scroll-button]')).forEach((container) => {
      this.buttonObjects.push(new ButtonObject(container));
    });
  }

  elementModify() {
    this.elementHeight = this.items[0].offsetHeight;
    this.items.forEach((item, index) => {
      if (index !== 0) {
        this.elementHeight += item.offsetWidth;
      }
      if (!this.valuePositions[item.dataset.slideDecade]) {
        this.valuePositions[item.dataset.slideDecade] = {
          position: item.getBoundingClientRect().left,
          width: item.offsetWidth,
        };
      } else {
        this.valuePositions[item.dataset.slideDecade].width += item.offsetWidth;
      }
    });
    this.element.style.height = `${this.elementHeight}px`;
    this.element.style.position = 'relative';
  }

  bind() {
    this.bindOnScroll();
  }

  bindOnScroll() {
    window.addEventListener('scroll', (e) => {
      if (this.preloaderLoaded) {
        this.positionObject = this.getPositionObject();

        // this.scrolled = this.positionObject.scrollTop;

        // if (this.element.offsetHeight > this.inner.scrollLeft) {
          // this.scrolled = window.pageYOffset;
          // inner.scrollLeft = (1 - this.ease) * inner.scrollLeft + this.ease * window.pageYOffset;
        // }
      }
    });
  }

  progressHandle() {
    this.progressBarOpacityToggle();
    const currentDecade = this.getCurrentDecade();
    this.currentDecade.element = currentDecade;
    if (currentDecade) {
      this.setProgressBar(currentDecade);
    }
  }

  getCurrentDecade() {
    return Object.entries(this.valuePositions)
      .find((value) => {
        return value[1].position <= this.rightBorder && value[1].position + value[1].width > this.rightBorder;
      });
  }

  // Смотрит текущее десятилетие и двигает прогресс бар
  setProgressBar(currentDecade) {
    const currentDecadeNumber = parseInt(currentDecade[0], 10);
    const currentProgressLine = this.progressLines[currentDecadeNumber];
    const percent = (this.rightBorder - currentDecade[1].position) / (currentDecade[1].width / 100);
    currentProgressLine.style.width = `${percent}%`;

    // Если текущее десятилетие первое
    if (currentProgressLine === this.progressLines[1]) {
      this.progressLines[0].classList.add('_full');
      this.setPrevDateActive(currentDecadeNumber);
      this.setNextProgressLineWidthZero(currentDecadeNumber);
      this.setCurrentDataOpacityFifty(currentDecadeNumber);
      // Если текущее десятилетие последнее
    } else if (currentProgressLine === this.progressLines[this.progressLines.length - 1]) {
      this.setPrevProgressLineWidthHundred(currentDecadeNumber);
      this.setPrevDateActive(currentDecadeNumber);
      // Если текущее десятилетие не первое и не последнее
    } else if (currentProgressLine !== this.progressLines[1] &&
               currentProgressLine !== this.progressLines[this.progressLines.length - 1]) {
      this.setNextProgressLineWidthZero(currentDecadeNumber);
      this.setCurrentDataOpacityFifty(currentDecadeNumber);
      this.setPrevProgressLineWidthHundred(currentDecadeNumber);
      this.setPrevDateActive(currentDecadeNumber);
    }
    // Если скролл в верху экрана
    if (currentProgressLine === this.progressLines[1] && this.positionObject.scrollTop === 0) {
      this.progressLines[0].classList.remove('_full');
      this.progressDates[currentDecadeNumber - 1].style.color = 'var(--color-white-opacity-50)';
    }
  }

  setPrevDateActive(current) {
    this.progressDates[current - 1].style.color = 'white';
  }

  setCurrentDataOpacityFifty(current){
    this.progressDates[current].style.color = 'var(--color-white-opacity-50)';
  }

  setNextProgressLineWidthZero(current) {
    this.progressLines[current + 1].style.width = '0';
  }

  setPrevProgressLineWidthHundred(current) {
    this.progressLines[current - 1].style.width = '100%';
  }

  progressBarOpacityToggle() {
    if (this.positionObject.scrollTop > window.innerWidth - ((window.innerWidth / 100) * 10)) {
      this.progressBarContainer.style.opacity = '1';
    } else {
      this.progressBarContainer.style.opacity = '0';
    }
  }

  setTranslate() {
    // this.translate = Math.round((1 - this.ease) * this.translate + this.ease * this.scrolled);
    this.translate = (1 - this.ease) * this.translate + this.ease * this.positionObject.scrollTop;
    if (this.translate < 0) {
      this.translate = 0;
    }
    // this.inner.scrollLeft = this.translate;
    this.list.style.transform = `translate3d(${this.translate * -1}px, 0, 0)`;
    this.setRightBorder();
  }

  setRightBorder() {
    this.rightBorder = this.translate + window.innerWidth;
  }

  getPositionObject() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollBottom = scrollTop + window.innerHeight;

    const bounds = this.element.getBoundingClientRect();
    const offsetTop = bounds.top + scrollTop;
    const offsetBottom = offsetTop + bounds.height;

    return {
      scrollTop,
      scrollBottom,
      offsetTop,
      offsetBottom,
      height: bounds.height,
    };
  }
}

function initHorizonScroller() {
  const horizonElement = document.querySelector('[data-horizon]');
  if (horizonElement) {
    setTimeout(() => {
      if (window.innerWidth >= 1024) {
        const horizon = new HorizonScroller(horizonElement);
      }
    }, 2000);
  }
}

document.addEventListener('DOMContentLoaded', initHorizonScroller);