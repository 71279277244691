import AwaitAnimationRemover from '../animation/awaitAnimationRemover';

class MediumDownScroll {
  constructor() {
    this.awaitAnimationRemover = new AwaitAnimationRemover();
    this.update();
  }

  update() {
    this.awaitAnimationRemover.update();
    window.requestAnimationFrame(this.update.bind(this));
  }
}

function init() {
  setTimeout(() => {
    const mediumDownScroll = new MediumDownScroll();
  }, 2000);
}

document.addEventListener('DOMContentLoaded', init);