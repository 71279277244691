// На строку которую нужно разобрать вешаем "data-text-wrap"

class TextWrapCreator {
    constructor(selector) {
        this.element = selector;
        this.elementClass = this.element.className.slice(0, this.element.className.indexOf(' '));
        this.originalString = this.element.innerHTML.trim();
        this.words = this.originalString.split('');
        this.line = '';
        this.create();
    }

    create() {
        this.element.classList.add('word-anim__container');
        this.element.innerHTML = '';
        for(let n = 0; n < this.words.length; n++) {
            this.createNewElement(this.words[n]);
        }
    }

    createNewElement(letter) {
        const newElement = document.createElement('span');
        newElement.classList.add(`${this.elementClass}-item`);
        newElement.classList.add('word-anim__letter');
        newElement.innerHTML = letter;
        this.element.append(newElement);
    }
}

function init() {
    document.querySelectorAll('[data-text-wrap]').forEach((item) => {
        const textWrapCreator = new TextWrapCreator(item);
    });
}

document.addEventListener('DOMContentLoaded', init);